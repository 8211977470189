import axios from "axios";
import debug from "debug";
import qs from "qs";

const log = debug("sde:axios");

const instance = axios.create({
  baseURL: "/api/v2/",
  paramsSerializer: {
    serialize: (params) =>
      qs.stringify(params, {
        arrayFormat: "repeat", // converts { tag: ['tag1', 'tag2'] } -> tag=tag1&tag=tag2
        encode: true,
      }),
  },
  xsrfCookieName: "sde-csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  maxRedirects: 0,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

instance.interceptors.response.use(
  (response) => {
    const queryCount =
      response.headers && response.headers["x-debug-query-count"];
    const { method, url } = response.config;
    if (queryCount) {
      log(`QUERY COUNT: ${queryCount} ----- ${method.toUpperCase()} ${url}`);
    }
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      const { pathname, search, hash } = window.location;
      const customLoginURL = window.django.custom_login_url;
      const loginURL = `${window.django.login_url}?next=${pathname}${search}${hash}`;

      window.location = customLoginURL || loginURL;
      return;
    }
    return Promise.reject(error);
  }
);

export default instance;
