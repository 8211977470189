import { FormikHelpers, FormikValues, setIn } from "formik";
import { reduce } from "lodash";
import { AxiosResponse } from "axios";
import { SdeFormikErrors } from "./interfaces";

interface GetSubmitHandlerProps<Values> {
  onSubmit?: (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  void | Promise<any>;
  onSubmitFail?: (formikHelpers: FormikHelpers<Values>) => void;
}

export function reduceNetworkErrors(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: AxiosResponse<any>
) {
  return reduce(response.data, (acc, value, key) => setIn(acc, key, value), {
    _error: response.data.errors,
  });
}

export const getSubmitHandler =
  <Values extends FormikValues>({
    onSubmit,
    onSubmitFail,
  }: GetSubmitHandlerProps<Values>) =>
  async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    let success = true;

    if (!onSubmit) {
      return;
    }

    try {
      return await onSubmit(values, formikHelpers);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (response: any) {
      success = false;
      if (response && response.data) {
        const errors = reduceNetworkErrors(response);
        formikHelpers.setErrors(errors as SdeFormikErrors<Values>);
      }
    } finally {
      formikHelpers.setSubmitting(false);

      if (!success) {
        onSubmitFail && onSubmitFail(formikHelpers);
      }
    }
  };
