import debug from "debug";
import checkPerm from "../perms";
import checkFeature from "../features";

export const isPermitted = (props) => {
  const {
    buVisible,
    features,
    globalPerms,
    projectPerms,
    requiredFeature,
    requiredPermission,
    user = {},
    requireSuperuser,
    requiresVisibleBu,
  } = props;

  if (requiresVisibleBu && !buVisible) {
    return false;
  }

  if (
    (requiredFeature && !checkFeature(features)(requiredFeature)) ||
    (requireSuperuser && !user.isSuperuser)
  ) {
    return false;
  }

  if (requiredPermission) {
    return checkPerm(globalPerms, projectPerms)(requiredPermission);
  }

  return true;
};

export const filterVisible = (features, globalPerms, user, children) => {
  const childArray = Array.isArray(children) ? children : [children];

  return childArray.filter((child) => {
    let WrappedComponent;
    if (child.type) {
      ({ WrappedComponent } = child.type);
    }

    if (!WrappedComponent || WrappedComponent.componentName !== "CheckPerms") {
      return true;
    }

    return isPermitted({
      ...child.props,
      user,
      features,
      globalPerms,
    });
  });
};

export const assertIfProjectPermsRequired = (props) => {
  const log = debug("sde:CheckPerms");
  const { projectPerms, requiredPermission } = props;

  const projPermsRequired = requiredPermission && requiredPermission.length > 1;
  const projPermsSupplied = Array.isArray(projectPerms);

  if (projPermsRequired && !projPermsSupplied) {
    log("projectPerms prop required.");
  }
};
