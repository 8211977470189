import React from "react";
import { createAction } from "@reduxjs/toolkit";
import { VariantType } from "notistack";
import { ActionClickHandler, DjangoVariantType, Toast } from "./types";

export const addToast = createAction<Toast>("ADD_TOAST");
export const removeToast = createAction<number>("REMOVE_TOAST");

/**
 * Used to make toasts appear on a page!
 */
export const makeToast = (
  status: VariantType,
  text: React.ReactNode,
  actionText?: string,
  onActionClick?: ActionClickHandler
) => {
  // We use this as the key for rendering and a way to do removal
  const id = new Date().valueOf();

  let props: Toast = {
    id,
    status,
    text,
    actionText,
  };

  if (onActionClick) {
    props = {
      ...props,
      autoHideDuration: 10000,
      onActionClick,
    };
  }

  return addToast(props);
};

/**
 * Used to make django toasts appear on a page!
 */
export const makeDjangoToast = (
  type: DjangoVariantType,
  text: React.ReactNode,
  extraTags: string
) => {
  const status = type === "debug" ? "info" : type;
  return makeToast(status as VariantType, text, extraTags);
};
