// See https://waffle.readthedocs.io/en/stable/usage/javascript.html

export type Waffle = {
  FLAGS: Record<string, boolean>;
  SWITCHES: Record<string, boolean>;
  SAMPLES: Record<string, boolean>;
  flag_is_active(flagName: string): boolean;
  switch_is_active(switchName: string): boolean;
  sample_is_active(sampleName: string): boolean;
};

// For unit tests where WaffleJS is unavailable
const mockWaffle: Waffle = {
  FLAGS: {},
  SWITCHES: {},
  SAMPLES: {},
  // eslint-disable-next-line camelcase
  flag_is_active: (flagName) => mockWaffle.FLAGS[flagName],
  // eslint-disable-next-line camelcase
  switch_is_active: (switchName) => mockWaffle.SWITCHES[switchName],
  // eslint-disable-next-line camelcase
  sample_is_active: (sampleName) => mockWaffle.SAMPLES[sampleName],
};

export default (window.waffle || mockWaffle) as Waffle;
