import { useEffect } from "react";
import { isEqual, isPlainObject } from "lodash";
import { connect, getIn } from "formik";
import diff from "object-diff";
import { useIsMount, usePrevious } from "_common/hooks";

/**
 * Executes a handler whenever a Formik form changes.
 */
const FormikObserver = ({ field, formik: { values }, onChange }) => {
  const isMount = useIsMount();
  const value = field ? getIn(values, field) : values;
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (isMount) {
      return;
    }

    if (onChange && !isEqual(prevValue, value)) {
      onChange(
        value,
        prevValue,
        isPlainObject(prevValue) && isPlainObject(value)
          ? diff(prevValue, value)
          : undefined
      );
    }
  }, [isMount, prevValue, value]);

  return null;
};

export default connect(FormikObserver);
